import axios from "@/plugins/axios";
import {
  parse_conditionFactor_to_create,
  parse_conditionFactor_to_update,
} from "@/services/references/conditionFactor/parsers";

/**
 * Список "Поправочные коэффициенты"
 * @return {Promise<conditionFactorObject[]>}
 */
export const API_conditionFactor_list = () => {
  return axios("/conditionFactor?perPage=5000", {
    method: "GET",
  })
    .then((response) => response.data.data)
    .catch((error) => {
      throw error;
    });
};

/**
 * Просмотр элемента "Поправочные коэффициенты"
 * @param  {number} id
 * @return {Promise<conditionFactorObject>}
 */
export const API_conditionFactor_view = (id) => {
  return axios(`/conditionFactor/${id}`, {
    method: "GET",
    headers: {
      "content-type": "application/json",
    },
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Обновление элемента "Поправочные коэффициенты"
 * @param  {number} id
 * @param {Object} value
 * @return {Promise<conditionFactorObject>}
 */
export const API_conditionFactor_update = (id, value) => {
  return axios(`/conditionFactor/${id}`, {
    method: "PUT",
    headers: {
      "content-type": "application/json",
    },
    data: parse_conditionFactor_to_update(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Создание элемента "Поправочные коэффициенты"
 * @param {Object} value
 * @return {Promise<conditionFactorObject>}
 */
export const API_conditionFactor_create = (value) => {
  return axios(`/conditionFactor/`, {
    method: "POST",
    headers: {
      "content-type": "application/json",
    },
    data: parse_conditionFactor_to_create(value),
  })
    .then((response) => {
      return response.data.data;
    })
    .catch((error) => {
      throw error;
    });
};

/**
 * Удаление элемента "Поправочные коэффициенты"
 * @param  {number} id
 * @return {Promise<boolean>}
 */
export const API_conditionFactor_delete = (id) => {
  return axios(`/conditionFactor/${id}`, {
    method: "DELETE",
    headers: {
      "content-type": "application/json",
    },
  })
    .then(() => {
      return true;
    })
    .catch((error) => {
      throw error;
    });
};
