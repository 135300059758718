<template>
  <form @submit.prevent="submit">
    <div class="d-flex justify-content-between align-items-center mb-3">
      <h2 v-text="titlePage"></h2>
      <div class="d-flex gap-3">
        <UIButton
          type="button"
          v-show="!editable"
          text="Редактировать"
          leftIcon="bi-pencil"
          @click="goEdit"
        />
        <UIButton
          type="submit"
          v-show="editable"
          text="Сохранить"
          leftIcon="bi-save"
        />
        <UIButton
          v-show="editable"
          text="Удалить"
          leftIcon="bi-trash"
          @click="deleteButton"
          float
        />
      </div>
    </div>
    <div class="row gap-3" v-if="conditionFactor">
      <dl class="col row gap-2">
        <div class="row gap-2">
          <div>
            <dt class="col">Наименование:</dt>
            <dl class="col">
              <EditableInput
                v-model="
                  conditionFactor.WorkIdentifiedObject.IdentifiedObject.name
                "
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Шифр:</dt>
            <dl class="col">
              <EditableInput
                v-model="
                  conditionFactor.WorkIdentifiedObject.IdentifiedObject
                    .enproCode
                "
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Значение:</dt>
            <dl class="col">
              <EditableInput
                v-model="conditionFactor.cfValue"
                type="number"
                :editable="editable"
                required
              />
            </dl>
          </div>
        </div>
      </dl>
      <dl class="col">
        <div class="row gap-2">
          <div>
            <dt class="col">Группа:</dt>
            <dl class="col">
              <EditableSelect
                :value="lodash.get(conditionFactor, 'CFGroup.id')"
                @input="(e) => lodash.set(conditionFactor, 'CFGroup.id', e)"
                :options="groups"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Тип:</dt>
            <dl class="col">
              <EditableSelect
                :value="lodash.get(conditionFactor, 'kind.id')"
                @input="(e) => lodash.set(conditionFactor, 'kind.id', e)"
                :options="kind"
                :editable="editable"
                required
              />
            </dl>
          </div>

          <div>
            <dt class="col">Статус:</dt>
            <dl class="col">
              <EditableSelect
                :value="lodash.get(conditionFactor, 'status.id')"
                @input="(e) => lodash.set(conditionFactor, 'status.id', e)"
                :options="status"
                :editable="editable"
                required
              />
            </dl>
          </div>
        </div>
      </dl>
    </div>
  </form>
</template>

<script>
import UIButton from "@/components/UI/Button";
import EditableInput from "@/components/UI/EditableInput";
import EditableSelect from "@/components/UI/EditableSelect";
import {
  API_conditionFactor_delete,
  API_conditionFactor_update,
  API_conditionFactor_view,
} from "@/services/references/conditionFactor";
import { API_StatusList } from "@/services/api";
import { API_cFGroup_list } from "@/services/references/cFGroup";
import { API_GetAllKindsInModel } from "@/services/kind";

export default {
  name: "conditionFactorViewEdit",
  components: { UIButton, EditableInput, EditableSelect },
  data() {
    return {
      /**
       * @type conditionFactorObject|null
       */
      conditionFactor: null,
      status: [],
      groups: [],
      kind: [],
    };
  },
  computed: {
    editable: function () {
      switch (this.$attrs.mode) {
        case "edit":
          return true;
        default:
          return false;
      }
    },
    titlePage: function () {
      if (this.editable) {
        return "Редактирование поправочного коэффициента";
      }
      return "Просмотр поправочного коэффициента";
    },
  },
  mounted() {
    API_conditionFactor_view(parseInt(this.$route.params.id)).then(
      (res) => (this.conditionFactor = res)
    );
    API_StatusList().then((res) => (this.status = res));
    API_cFGroup_list().then((res) => {
      this.groups = Array.from(res, (item) => ({
        id: item.id,
        value: item.WorkIdentifiedObject.IdentifiedObject.name,
      }));
    });
    API_GetAllKindsInModel("ConditionFactorKind").then((res) => {
      this.kind = res;
    });
  },
  methods: {
    goEdit() {
      this.$router.push({
        path: `/references/technological-maps/condition-factors/${this.$route.params.id}/edit`,
      });
    },
    submit() {
      API_conditionFactor_update(
        parseInt(this.$route.params.id),
        this.conditionFactor
      ).then((res) => {
        this.$router.push({
          path: `/references/technological-maps/condition-factors/${res.id}`,
        });
      });
    },
    deleteButton() {
      API_conditionFactor_delete(parseInt(this.$route.params.id)).then(() => {
        this.$router.push({
          path: `/references/technological-maps/condition-factors/`,
        });
      });
    },
  },
};
</script>

<style scoped></style>
