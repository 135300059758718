import { createConditionFactorSchema } from "@/services/references/conditionFactor/schemas";

export const parse_conditionFactor_to_create = (value) => {
  let data = Object.assign({}, createConditionFactorSchema);
  data.cfValue = value.value;
  data.status_id = value.statusId;
  data.kind_id = value.kindId;
  data.c_f_group_id = value.groupId;
  data.WorkIdentifiedObject.IdentifiedObject.name = value.name;
  data.WorkIdentifiedObject.IdentifiedObject.enproCode = value.code;
  return data;
};

export const parse_conditionFactor_to_update = (value) => {
  let data = Object.assign({}, value);
  data.cfValue = value.value;
  data.kind_id = value.kind.id;
  data.status_id = value.status.id;
  data.c_f_group_id = value.CFGroup.id;
  return data;
};
