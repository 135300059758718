export let createConditionFactorSchema = {
  WorkIdentifiedObject: {
    IdentifiedObject: {
      name: "",
      description: null,
      enproCode: "",
      names: [],
    },
  },
  status_id: 0,
  kind_id: 0,
  c_f_group_id: 0,
  cfValue: "",
};
